<template>
    <div v-if="isLoading">
        <div class="text-center">
            <b-spinner></b-spinner>
        </div>
    </div>
    <div v-else>
        <b-table
            striped
            hover
            sort-desc.sync="true"
            :items="items"
            :fields="fields"
            :sort-by.sync="sort"
            responsive
            class="m-0 text-center"
        >
            <template v-slot:cell(name)="data" :data-sorter="data.item.name">
                <div class="d-flex flex-row justify-content-start ml-2">
                    <img :src="data.item.avatar" alt="" class="h-30px align-self-middle rounded" />
                    <span class="ml-2 my-auto font-weight-bold">
                        {{ data.item.name }}
                    </span>
                </div>
            </template>
            <template v-slot:cell(customer)="data">
                {{ data.item.customer }}
            </template>
            <template v-slot:cell(action)="data">
                <div :id="'button-' + data.item.id" class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-icon btn-circle btn-light btn-sm" @click="clicked(data.item)">
                        <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                        </span>
                    </button>
                </div>
                <b-spinner :id="'spinner-'+ data.item.id" class="align-self-center d-none" variant="primary"></b-spinner>

            </template>
            <template v-slot:cell(created_at)="data" :data-sorter="data.item.created_at">
                {{ data.item.created_at | moment('Do MMMM YYYY') }}
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_SOURCE_EXT } from '@/core/services/store/api/tableSource'

export default {
    props: ['sortBy'],
    data() {
        return {
            sort: this.sortBy,
            dire: true,
            fields: [
                { key: 'name', label: this.$t('INTERN.NAME'), sortable: true, class: 'text-left' },
                { key: 'customer', label: this.$t('INTERN.CUSTOMER'), sortable: true },
                { key: 'action', label: '', sortable: false}
            ],
            items: [],
            isLoading: true
        }
    },
    mounted() {
        this.formatUsers()
        this.isLoading = false
    },
    methods: {
        async clicked(item) {
            //remove class "d-none" from spinner where id = spinner-item.id
            document.getElementById('button-' + item.id).classList.remove('d-flex')
            document.getElementById('button-' + item.id).classList.add('d-none')
            document.getElementById('spinner-' + item.id).classList.remove('d-none')
            let params = {
                tablename: 'users',
                tableid: item.id
            }
            await this.$store.dispatch(GET_SOURCE_EXT, { params }).then(result => {
                let extid = 'null'
                if (result.extid) {
                    extid = result.extid
                }
                return this.$router.push({
                    name: 'intern.view',
                    params: { extid: extid, plateformeid: item.id }
                })
            })
        },
        formatUsers() {
            this.items = []
            return (
                this.interns.data &&
                this.interns.data.map(intern =>
                    this.items.push({
                        id: intern.id,
                        avatar: intern.avatar,
                        name: `${intern.lastname} ${intern.firstname}`,
                        firstname: intern.firstname,
                        lastname: intern.lastname,
                        customer: intern.customer && intern.customer.name,
                        created_at: intern.created_at
                    })
                )
            )

            // return (this.interns.data &&
            //     this.interns.data.map(intern =>
            //         this.items.push({
            //             id: intern.id,
            //             avatar: 'https://avatars.dicebear.com/api/initials/' + intern.sta_nom + '.svg?chars=1',
            //             name: `${intern.sta_nom} ${intern.sta_prenom}`,
            //             firstname: intern.sta_prenom,
            //             lastname: intern.sta_nom,
            //             customer: intern.sta_client_code && intern.sta_client_nom,
            //         })
            //     ))
        }
    },
    watch: {
        interns: function() {
            this.formatUsers()
        },
        sort: function(newValue) {
            this.$emit('change-sort', {
                sortBy: newValue,
                dir: this.dir
            })
        },
        dir: function(newValue) {
            this.$emit('change-sort', {
                sortBy: this.sort,
                dir: newValue
            })
        }
    },
    computed: {
        ...mapGetters({
            interns: 'listInterns'
        })
    }
}
</script>
